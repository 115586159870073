import React from 'react';
import Bluebanner from "../../../components/bluebanner"
import Homeheader from "../../../components/home_header"
import Fullnav from "../../../components/fullnav"
import * as style from "./styles.module.scss"
import Container from '@material-ui/core/Container'
import Drawer from '@material-ui/core/Drawer';
import Slidepanel from "../../../components/slidepanel"
import { Button } from '@material-ui/core';
import mainlogosmall from "../../../images/logo_port_slim_small.svg"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from "gatsby"
import CaseHeader from '../../../components/case_header';
import caseimg from "../../../images/relationship.png"
import Sectionheaderdark from "../../../components/typography/section_headers/dark"
import Card from '@material-ui/core/Card';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AlbumIcon from '@material-ui/icons/Album';
import classNames from 'classnames'
import Sectionheaderlight from '../../../components/typography/section_headers/light';
import Remotetabs from "./tabs"
import Remoteimg from "../../../images/relationship_brainstorm.png"
import Footer from '../../../components/footer';
import {StaticImage} from "gatsby-plugin-image"




const Remote = () => {
    return (
        <div >

            <Container className={style.menucontainer} maxWidth={false} disableGutters={true}>
                <Container>
                    <div className={style.onlymobile}>
                        <Fullnav ></Fullnav>
                    </div>
                    <div id="white" className={style.simplenav}>
                        <div className={style.adjuster}>
                        <div className={style.backlink} >
                        <Link to="/"><ArrowBackIosIcon/> 
                        <img src={mainlogosmall}/>                    
                        </Link><span>/</span><span><Link className={style.backto} to="/#past">All Projects</Link></span><span>/</span><span className={style.currentlink}>Remote Relationships</span>
                            </div>
                        </div>

                        <Slidepanel></Slidepanel>

                    </div>
                </Container>
            </Container >
            <CaseHeader label="Remote Relationships"
                description="Remote relationships are hard for both partners involved as distance can cause several problems if not consistently given enough attention. An effective way to be aware of the communication gaps is imperative and useful for partners involved in a remote relationship. "
                high1="A survey with 72 participants based on the works of Hassenzahl et al. on Remote relationships"
                high2="3D printed model emulating a real life flower used to represent communication frequency  "
                high3="Utilizing Photon Particle and IFTTT to enable connection between the prototypes"
                img={caseimg}
                gradient1
            ></CaseHeader>
            {/* <Container maxWidth={false} disableGutters={true}>
                <Drawer className={style.pf_sidepanel} variant="permanent"></Drawer>
            </Container> */}
            <Container maxWidth={false} disableGutters={true} className={style.darkbg}>
                <Container>
                    <Sectionheaderdark label=" My Role" className={style.pf_all_white_text} ></Sectionheaderdark>
                    <div className={style.cardgrid}>
                        <Card className={style.casecard}>
                            <ul >
                                <li className={style.ligrid}>
                                    <CheckCircleIcon className={style.blue} />User Research
                                </li>
                                <li>
                                    <p>
                                        Evaluate and finalize research strategy and approach. Identify research participants and create story boards & personas
                                </p>
                                </li>
                            </ul>
                        </Card>
                        <Card className={style.casecard}>
                            <ul >
                                <li className={style.ligrid}>
                                    <CheckCircleIcon className={style.blue} />Technology Research 
                                </li>
                                <li>
                                    <p>
                                        Identify technology that can enable communication between device prototypes when located at different geolocation.
                                </p>
                                </li>
                            </ul>
                        </Card>
                        <Card className={style.casecard}>
                            <ul >
                                <li className={style.ligrid}>
                                    <CheckCircleIcon className={style.blue} />Build Prototype
                                </li>
                                <li>
                                    <p>
                                        3D design for the device emulating a real life flower movement. Research on 3d materials suitable for the device prototype functions.
                                </p>
                                </li>
                            </ul>
                        </Card>
                    </div>
                </Container>
            </Container>
            <Container className={style.pf_container_pad}>
                <Sectionheaderlight caption="Identifying" label="The User Needs" />
                <Card className={classNames(style.casecard, style.highcard)}>
                    <div className={style.highggrid}>
                        <div>
                            <h2>Is there a need for a solution?</h2>
                            <p>A survey with 72 participants based on the works of Hassenzahl et al. The framework proposed included strategies necessary for designing for remote relationships. The result of the survey indicated Emotional expression and awareness to be most important, which became the main consideration of the design concept.</p>
                        </div>
                        <div>
                            <h2>What solutions do the users expect?</h2>
                            <p>Post and pre deployment surveys based on Hendricks Relationship Assessment to understand participants communication modes, quantity and quality </p>

                        </div>

                    </div>

                    <div>
                        <StaticImage
                        src="../../../images/relationship_brainstorm.png" 
                        placeholder="blurred"
                        />
                    </div>

                </Card>
            </Container>
            <Container className={classNames(style.greyblock, style.pf_container_pad)} maxWidth={false} disableGutters={true}>
                <Container>
                    <Sectionheaderlight caption="Design Artifacts" label="Based on Research Insights" />

                    <Remotetabs />

                </Container>
            </Container>
            <Container maxWidth={false} disableGutters={true} className={style.pf_container_pad}>
                <Container>
                    <Sectionheaderlight caption="Prototype" label=" Testing and Future Vision" className={style.pf_all_white_text} ></Sectionheaderlight>
                    <div className={style.conclusion}>
                        <div>
                            <h3>A Good Start</h3>
                            <p>
                                The working prototype was well received and definitely provided a string platform to build and continue
                                the effort. The next iteration will make use of latest technology like Machine Learning and AI to leverage the technological
                                advancements we are already witnessing.
                            </p>
                            <h3>Areas of focus</h3>
                            <ul className={style.goalslist}>
                            <li className={style.ligrid}>
                                    <AlbumIcon className={style.blue} /> Product design with focus on prototype robustness and appeal. Think beyond the flower model.
                            </li>
                                <li className={style.ligrid}>
                                    <AlbumIcon className={style.blue} /> Make use of ML Algorithms to catalog communication patterns between partners
                            </li>
                                <li className={style.ligrid}>
                                    <AlbumIcon className={style.blue} /> Incorporate Voice API for activation and basic functions on the Prototype
                            </li>
                                <li className={style.ligrid}>
                                    <AlbumIcon className={style.blue} /> Provide a holistic platform to enrich long distance relationships, not restricted to romantic relationships
                            </li>

                            </ul>

                        </div>
                        <div>
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/5xqy_X_6kEI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </Container>

            </Container>
<Footer/>

        </div>

    )
}


export default Remote


