// extracted by mini-css-extract-plugin
export var adjuster = "styles-module--adjuster--jLFSE";
export var autotabs = "styles-module--autotabs--Z42xs";
export var backlink = "styles-module--backlink--uF-Ob";
export var backto = "styles-module--backto--Ot2jF";
export var backtransparent = "styles-module--backtransparent--lxYZE";
export var blue = "styles-module--blue--2oxJj";
export var cardgrid = "styles-module--cardgrid--rnJQ4";
export var casecard = "styles-module--casecard--7LxJK";
export var conclusion = "styles-module--conclusion--vKiqo";
export var currentlink = "styles-module--currentlink--Uxu1u";
export var darkbg = "styles-module--darkbg--wfQHi";
export var goalslist = "styles-module--goalslist--AxEVT";
export var greyblock = "styles-module--greyblock--FeFfF";
export var highcard = "styles-module--highcard--XXfQR";
export var highggrid = "styles-module--highggrid--ZvPBM";
export var iframe = "styles-module--iframe--032AR";
export var iframebig = "styles-module--iframebig--GP1uV";
export var iframeopen = "styles-module--iframeopen--+PnKo";
export var ligrid = "styles-module--ligrid--jWiWu";
export var menucontainer = "styles-module--menucontainer--uMzrn";
export var mockpanel = "styles-module--mockpanel--Ap15r";
export var onlymobile = "styles-module--onlymobile--Jo+GO";
export var pf_80_width = "styles-module--pf_80_width--Fu-Yt";
export var pf_chain = "styles-module--pf_chain--EwYlA";
export var pf_container_pad = "styles-module--pf_container_pad--xf0Ei";
export var pf_sidepanel = "styles-module--pf_sidepanel--SV9T2";
export var simplenav = "styles-module--simplenav--Inyvb";
export var support = "styles-module--support--B-W4w";
export var tabcontainer = "styles-module--tabcontainer--br9ne";
export var tabpanelcon = "styles-module--tabpanelcon--dFMcA";
export var tabpanelcontent = "styles-module--tabpanelcontent--+Wg4O";